<template>
  <div class="pa-3">
    <div class="text-right mb-8  mr-4">
      <v-btn :disabled="!checkPerUser" class="btn--primary btn-cus" @click="openAddDialog = true">
        <v-icon class="mr-1"> mdi-plus </v-icon>
        {{ $t("buttons.registerNewParkingLot") }}
      </v-btn>
    </div>
    <v-simple-table class="simple-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th>{{ $t("common.title") }}</th>
            <th>{{ $t("facility.settings.vehicleHeightLimit") }}</th>
            <th>
              {{ $t("common.quantity") }}
            </th>
            <th class="text-right">
              {{ $t("facility.settings.waitingList") }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in facilityParkingList" :key="item.id">
            <td>
              <v-text-field
              @input="setChangeName($event); getValueItemInName(item.id, item.maxHeight, item.quantity, item.allowCancelWait)"
                outlined
                :disabled="!checkPerUser"
                dense
                class="pa-0"
                v-model="item.name"
                :rules="[() => !!item.name ||$t('facility.rentalList.rules.required'),
                    () => item.name.length < maxInt ||$t('rules.isInvalid')]"
              ></v-text-field>
            </td>
            <td>
              <v-select
              @input="setChangeMaxHeight($event); getValueInMaxHeight(item.id, item.name, item.quantity, item.allowCancelWait)"
                hide-details
                :disabled="!checkPerUser"
                outlined
                dense
                :value="getHeight(item.maxHeight)"
                :items="listMaxHeight"
              ></v-select>
            </td>
            <td>
              <v-select
                @input="setChangeQuantity($event); getValueInQuantity(item.id, item.name, item.maxHeight, item.allowCancelWait)"
                hide-details
                :disabled="!checkPerUser"
                outlined
                dense
                v-model="item.quantity"
                :items="listQuantity"
              ></v-select>
            </td>
            <td>
              <v-checkbox
                :disabled="!checkPerUser"
                @change="setChangeCheckBox($event); getValueInCheckBox(item.id, item.name, item.maxHeight, item.quantity)"
                hide-details
                class="pa-0 mr-5 my-0 float-right"
                v-model="item.allowCancelWait"
              ></v-checkbox>
            </td>
            <td class="text-right">
              <v-btn :disabled="!checkPerUser" text color="var(--text__red)" @click="setValueParkingId(item.id)">{{
                $t("buttons.delete")
              }}</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-right mt-15 mr-4">
      <v-btn :disabled="!checkPerUser" class="btn--dark-blue mr-3 btn-cus" @click="updateAll(false)">{{
        $t("buttons.saveAndUpdate")
      }}</v-btn>
      <v-btn :disabled="!checkPerUser" class="btn--dark-blue btn-cus" @click="updateAll(true)">{{ $t("buttons.saveAndClose") }}</v-btn>
    </div>

    <base-dialog
      width="500px"
      :visible="openAddDialog"
      @close="openAddDialog = false"
      :title="$t('facility.settings.parkingLotRegistration')"
      isGrayDialog
    >
      <template v-slot:content>
       <v-form ref="form">
          <div class="t-table px-5 mt-8">
          <div class="t-table__row">
            <label style="font-size:18px; color: #666666">{{ $t("common.title") }}</label>
            <v-text-field
              outlined
              dense
              class="pa-0"
              v-model="newName"
              :rules="[
                () => !!newName || $t('facility.rentalList.rules.required'),
                () => newName.length < maxInt ||$t('rules.isInvalid')]"
            ></v-text-field>
          </div>

          <div class="t-table__row">
            <label class="t-label" style="font-size:18px; color: #666666">{{
              $t("calendar.parkingCars.carHeight")
            }}</label>
            <v-select
              @change="getSelectMaxHeight($event)"
              small
              dense
              outlined
              color="var(--text__gray)"
              v-model="newHeightLimit"
              :items="listMaxHeight"
            ></v-select>
          </div>

          <div class="t-table__row">
            <label class="t-label" style="font-size:18px; color: #666666">{{ $t("common.quantity") }}</label>
            <v-select
             :rules="[
                () => !!newQuantity || $t('facility.rentalList.rules.required')]"
              @change="getSelectValueQuantity($event)"
              small
              dense
              outlined
              color="var(--text__gray)"
              v-model="newQuantity"
              :items="listQuantity"
            ></v-select>
          </div>
        </div>
       </v-form>
      </template>

      <template v-slot:footer>
        <div class="text-right mt-5 px-5">
          <v-btn :disabled="!checkPerUser" large class="btn--pink mr-5" style="font-size:19px" @click="openAddDialog = false">{{
            $t("buttons.cancel")
          }}</v-btn>
          <v-btn :disabled="!checkPerUser" large class="btn--dark-blue" style="font-size:19px" @click="handleSubmit()">{{
            $t("buttons.saveAndCreate")
          }}</v-btn>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { FACILITY_PARKING_LIST } from '@/api/graphql/facility-item-parking'
import { mapMutations, mapActions } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  apollo: {
    facilityParkingList: {
      query: gql`${FACILITY_PARKING_LIST}`,
      variables () {
        return {
          facilityId: parseInt(this.$route.query.id)
        }
      }
    }
  },
  name: 'ParkingPlace',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      arrItem: [],
      maxInt: 256,
      newCheckBox: false,
      newName: '',
      newMaxHeight: 0,
      listMaxHeight: ['なし'],
      listQuantity: [],
      facilityParkingList: [],

      // ========= DIALOG =========
      openAddDialog: false,

      newHeightLimit: 0,
      newQuantity: 0
      // =========================
    }
  },
  components: {
    BaseDialog
  },
  mounted () {
    this.getListItemMaxHeight()
    this.getListQuantity()
  },
  methods: {
    ...mapMutations({
      setFacilityItemParking: 'setFacilityItemParking',
      setAlert: 'setAlertSuccess'
    }),
    ...mapActions({
      actionCreate: 'createFacilityParking',
      actionUpdate: 'updateFacilityParking',
      actionDelete: 'deleteFacilityParking'
    }),
    createFacilityParking () {
      const facilityItemParking = {
        facilityId: parseInt(this.$route.query.id),
        name: this.newName,
        maxHeight: this.newMaxHeight,
        quantity: this.newQuantity,
        allowCancelWait: false
      }
      this.setFacilityItemParking({ facilityItemParking: facilityItemParking })
      this.actionCreate()
      this.$refs.form.resetValidation()
      this.newName = ''
      this.newHeightLimit = null
      this.newQuantity = null
    },

    getHeight (event) {
      if (event === 0) {
        return 'なし'
      } else return event
    },

    getListItemMaxHeight () {
      for (let i = 140; i < 251; i++) {
        this.listMaxHeight.push(i)
      }
    },
    getListQuantity () {
      for (let i = 0; i < 101; i++) {
        this.listQuantity.push(i)
      }
    },
    getSelectMaxHeight (event) {
      if (event === 'なし') {
        this.newMaxHeight = 0
      } else this.newMaxHeight = event
    },
    getSelectValueQuantity (event) {
      this.newQuantity = event
    },
    setChangeName (event) {
      this.newName = event
    },
    getValueItemInName (id, maxHeight, quantity, allowCancelWait) {
      const facilityItemParking = {
        name: this.newName,
        id: parseInt(id),
        maxHeight: parseInt(maxHeight),
        quantity: parseInt(quantity),
        allowCancelWait: allowCancelWait
      }
      this.arrItem.push(facilityItemParking)
    },
    setChangeMaxHeight (event) {
      if (event === 'なし') {
        this.newMaxHeight = 0
      } else this.newMaxHeight = event
    },
    getValueInMaxHeight (id, name, quantity, allowCancelWait) {
      const facilityItemParking = {
        name: name,
        id: parseInt(id),
        maxHeight: parseInt(this.newMaxHeight),
        quantity: parseInt(quantity),
        allowCancelWait: allowCancelWait
      }
      this.arrItem.push(facilityItemParking)
    },
    setChangeQuantity (event) {
      this.newQuantity = event
    },
    getValueInQuantity (id, name, maxHeight, allowCancelWait) {
      const facilityItemParking = {
        name: name,
        id: parseInt(id),
        maxHeight: parseInt(maxHeight),
        quantity: parseInt(this.newQuantity),
        allowCancelWait: allowCancelWait
      }
      this.arrItem.push(facilityItemParking)
    },
    setChangeCheckBox (value) {
      this.newCheckBox = value
    },
    getValueInCheckBox (id, name, maxHeight, quantity) {
      const facilityItemParking = {
        name: name,
        id: parseInt(id),
        maxHeight: parseInt(maxHeight),
        quantity: parseInt(quantity),
        allowCancelWait: this.newCheckBox
      }
      this.arrItem.push(facilityItemParking)
    },
    setValueParkingId (value) {
      const facilityItemParking = {
        id: value
      }
      this.setFacilityItemParking({ facilityItemParking: facilityItemParking })
      this.actionDelete()
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.createFacilityParking()
        this.openAddDialog = false
      }
    },
    async updateAll (status) {
      const arrayId = []
      const B = []
      for (let i = this.arrItem.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrItem[i].id)) {
          arrayId.push(this.arrItem[i].id)
          B.push(this.arrItem[i])
        }
      }
      for (let i = 0; i < B.length; i++) {
        this.setFacilityItemParking({ facilityItemParking: B[i] })
        await this.actionUpdate()
      }
      if (status) {
        this.setAlert(this.$t('messages.successfulUpdate'))
        setTimeout(() => {
          window.close()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field__slot {
    input {
    font-size: 18px !important;
    color: #666666 !important
    }
  }
  .v-select__selections {
    .v-select__selection--comma {
      font-size: 18px !important;
      color: #666666 !important;
    }
  }
  .v-data-table {
    min-height: 500px;
    thead tr th {
      white-space: nowrap;
      color: #666;
      &:nth-child(1) {
        width: 400px;
      }
      &:nth-child(2) {
        width: 100px;
      }
      &:nth-child(3) {
        width: 80px;
      }
      &:last-child {
        width: 70px;
      }
    }
    tbody tr td {
      color: #666;
      &:first-child,
      &:nth-child(2) {
        padding-right: 60px;
      }
      .v-select__selection, .v-text-field__slot input {
        font-size: 16px !important;
      }
    }
  }
  .v-text-field__details {
    display: none;
  }
}
.t-table {
  display: table;
  border-spacing: 0 20px;
  &__row {
    display: table-row;
    label,
    .v-input {
      display: table-cell;
    }
    label {
      padding-right: 15px;
      text-align: right;
      white-space: nowrap;
    }
    .v-input {
      width: 250px !important;
    }
  }
}
</style>
