<template>
  <div class="px-6 pt-6" style="max-width: 605px">
    <div class="d-flex justify-space-between mb-4">
      <h1 class="page-title-main">
        {{ $t("routeTitles.facility.bookingRemarkByContract") }}
      </h1>
      <div>
        <v-btn :disabled="!checkPerUser" large class="btn--red-dark" href="javascript:window.open('','_self').close();">
          <v-icon class="mr-2">mdi-close</v-icon>
          {{ $t("buttons.close") }}
        </v-btn>
        <v-btn :disabled="!checkPerUser" large class="btn--primary ml-3" @click="updateAll()">
          {{ $t("buttons.save") }}
        </v-btn>
      </div>
    </div>

    <!-- LIST BOOKING REMARKS BY CONTRACT -->
      <div v-for="item in facilityBookingRemarksList" :key="item.name">
        <h4 class="mb-3">{{ item.bookingType.code }}</h4>
        <v-textarea height="75" :disabled="!checkPerUser" outlined :value="item.remarks" @input="getValueUpdate($event); getItemId(item.bookingType.id, item.bookingType.code)"/>
      </div>
    <!-- -------------------------------- -->
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'BookingRemarks',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      arrItem: [],
      remarks: ''
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    ...mapGetters(['facilityBookingRemarksList'])
  },
  methods: {
    ...mapActions({
      actionUpdate: 'createFacilityBookingRemarks',
      fetchData: 'fetchFacilityBookingRemarksList'
    }),
    ...mapMutations({
      setBookingRemark: 'setBookingRemark',
      setAlert: 'setAlertSuccess'
    }),
    getValueUpdate (event) {
      this.remarks = event
    },
    getItemId (id, code) {
      const facilityBookingRemark = {
        bookingType: {
          id: parseInt(id),
          code: code
        },
        remarks: this.remarks
      }
      this.arrItem.push(facilityBookingRemark)
    },
    updateAll () {
      const arrayId = []
      const B = []
      for (let i = this.arrItem.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrItem[i].bookingType.id)) {
          arrayId.push(this.arrItem[i].bookingType.id)
          B.push(this.arrItem[i])
        }
      }
      for (let i = 0; i < B.length; i++) {
        this.setBookingRemark({ facilityBookingRemark: B[i] })
        this.actionUpdate()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
