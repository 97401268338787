<template>
  <div class="pa-3">
    <div class="text-right mb-8 mr-4">
      <v-btn :disabled="!checkPerUser" class="btn--primary btn-cus" @click="showDialog()">
        <v-icon class="mr-1"> mdi-plus </v-icon>
        <span style="font-size:16px">{{ $t("buttons.registerLoanItems") }}</span>
      </v-btn>
    </div>
    <v-simple-table class="simple-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="color: #666666">{{ $t("contractDetail.rentalListing") }}</th>
            <th style="color: #666666" class="text-right">{{ $t("common.totalAmount") }}</th>
            <th style="color: #666666" class="text-right">
              {{ $t("facility.settings.rentalUnitPriceTaxExclude") }}
            </th>
            <th style="color: #666666" >{{ $t("common.paymentUnit") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rentalItemList" :key="item.id">
            <td style="color: #666666">{{ item.rentalItemDef.name }}</td>
            <td style="color: #666666">
              <v-select
                @input="getSelectQuantity($event); setRentalItemId(item.rentalItemDef.id, item.id); "
                full-width
                class="float-right"
                hide-details
                outlined
                dense
                :value="item.quantity"
                :items="listItem"
                :disabled="!checkPerUser"
              ></v-select>
            </td>
            <td style="color: #666666" class="text-right">{{ item.rentalItemDef.price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }}</td>
            <td style="color: #666666">{{ item.rentalItemDef.rentalPeriod == 'Day' ? '一泊' : '一滞在' }}</td>
            <td class="text-right">
              <v-btn  :disabled="!checkPerUser" text color="var(--text__red)" @click="setValueRentalId(item.id)">{{
                $t("buttons.delete")
              }}</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-right mt-8 mr-1">
      <v-btn :disabled="!checkPerUser" color="#004F8B" class="mr-3" @click="updateAll(false)">
        <span style="font-size:16px; color: #fff">{{
        $t("buttons.saveAndUpdate")
      }}</span>
      </v-btn>
      <v-btn :disabled="!checkPerUser" color="#004F8B" class="mr-3 btn-cus" @click="updateAll(true)">
        <span style="font-size:16px; color: #fff">
          {{ $t("buttons.saveAndClose") }}
        </span>
      </v-btn>
    </div>

    <base-dialog
      @close="closeDialog()"
      width="500px"
      :visible="openAddDialog"
      :title="$t('buttons.registerLoanItems')"
      isGrayDialog
    >
      <template v-slot:content >
        <v-form ref="form">
          <div class="t-table px-5 mt-8">
          <div class="t-table__row" >
            <label class="t-label" style="font-size:16px;color:#666666">{{
              $t("contractDetail.rentalListing")
            }}</label>
            <v-select
              :rules="[
              () => !!rentalItemSelected || $t('facility.rentalList.rules.required')]"
              @input="getInput"
              small
              dense
              outlined
              color="var(--text__gray)"
              v-model="rentalItemSelected"
              :items="rentalListName"
            ></v-select>
          </div>

          <div class="t-table__row">
            <label class="t-label" style="font-size:16px;color:#666666">{{
              $t("common.totalAmount")
            }}</label>
            <v-select
            :rules="[
              () => !!quantitySelect || $t('facility.rentalList.rules.required')]"
              small
              dense
              outlined
              color="var(--text__gray)"
              v-model="quantitySelect"
              :items="listItem"
            ></v-select>
          </div>

          <div class="t-table__row">
            <label style="font-size:16px;color:#666666">{{ $t("facility.settings.rentalUnitPriceTaxExclude") }}</label>
            <div class="d-flex ">
               <input class="t-table__cell inputValue" style="text-align: right" disabled= true :value="facilityPrice.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })" />
            </div>
          </div>

          <div class="t-table__row">
            <label style="font-size:16px;color:#666666">{{ $t("common.paymentUnit") }}</label>
             <div class="d-flex ">
               <input class="t-table__cell inputValue" style="text-align: right" disabled= true v-model="facilityRentalPeriodName">
            </div>
          </div>
        </div>
        </v-form>
      </template>

      <template v-slot:footer>
        <div class="text-right mt-5 px-5">
          <v-btn :disabled="!checkPerUser" large class="btn--pink mr-5" @click="openAddDialog = false">
            <span style="font-size:17px">{{
            $t("buttons.cancel")
          }}</span>
          </v-btn>
          <v-btn :disabled="!checkPerUser" large color="#004F8B" @click="handleSubmit()">
            <span style="font-size:17px; color: #fff">
            {{
            $t("buttons.saveAndCreate")
          }}</span>
          </v-btn>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { RENTAL_ITEM_LIST } from '@/api/graphql/rental-item-for-facility'
import { RENTAL_ITEM_DEF_LIST } from '@/api/graphql/rental-item-def'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import gql from 'graphql-tag'

export default {
  name: 'RentalList',
  apollo: {
    rentalItemList: {
      query: gql`${RENTAL_ITEM_LIST}`,
      variables () {
        return {
          facilityId: parseInt(this.$route.query.id)
        }
      }
    },
    rentalList: gql`${RENTAL_ITEM_DEF_LIST}`
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      arrItem: [],
      rentalList: [],
      rentalItemList: [],

      // ======= Dialog =======
      openAddDialog: false,
      listRentalItem: [],
      rentalItemSelected: '',
      totalAmountSelected: 1,
      listItem: [],
      facilityPrice: 0,
      facilityrentalPeriod: '',
      facilityRentalPeriodName: '',
      rentalListName: [],
      rentalItemListName: [],
      rentalItemDefId: null,
      quantitySelect: null,
      getQuantity: 0,
      rentalItemDefIdUpdate: null,
      id: null,
      rentalItemDefName: ''
    }
  },
  components: {
    BaseDialog
  },
  computed: {
    ...mapGetters({ getItem: 'rentalItemFac' })
  },
  updated () {
    this.getListItemRental()
  },
  mounted () {
    this.getListItem()
  },
  methods: {
    getInput () {
      for (let i = 0; i < this.rentalList.length; i++) {
        if (this.rentalItemSelected === this.rentalList[i].name) {
          this.rentalItemDefName = this.rentalList[i].name
          this.rentalItemDefId = this.rentalList[i].id
          this.facilityPrice = this.rentalList[i].price
          this.facilityrentalPeriod = this.rentalList[i].rentalPeriod
          if (this.facilityrentalPeriod === 'Day') {
            this.facilityRentalPeriodName = '一泊'
          } else {
            this.facilityRentalPeriodName = '一滞在'
          }
        }
      }
    },
    getListItem () {
      for (let i = 0; i < 101; i++) {
        this.listItem.push(i)
      }
    },
    getListItemRental () {
      for (let i = 0; i < this.rentalList.length; i++) {
        this.rentalListName.push(this.rentalList[i].name)
      }
      for (let j = 0; j < this.rentalItemList.length; j++) {
        this.rentalItemListName.push(this.rentalItemList[j].rentalItemDef.name)
      }
      this.rentalListName = this.rentalListName.filter(e => !this.rentalItemListName.includes(e))
    },
    ...mapMutations({
      setRentalItemFac: 'setRentalItemFac',
      setAlert: 'setAlertSuccess'
    }),
    ...mapActions({
      actionCreate: 'createRentalItem',
      actionUpdate: 'updateRentalItem',
      actionDelete: 'deleteRentalItem'
    }),
    createRentalItem () {
      const rentalItemFac = {
        facilityId: this.$route.query.id,
        rentalItemDefId: this.rentalItemDefId,
        quantity: this.quantitySelect,
        rentalItemDefName: this.rentalItemDefName,
        facilityPrice: this.facilityPrice,
        facilityrentalPeriod: this.facilityrentalPeriod
      }
      this.setRentalItemFac({ rentalItemFac: rentalItemFac })
      this.actionCreate()
      this.openAddDialog = false
      this.rentalItemSelected = ''
      this.quantitySelect = null
      this.$refs.form.resetValidation()
    },
    getSelectQuantity (event) {
      this.getQuantity = event
    },
    setRentalItemId (rentalDefId, id) {
      const rentalItemFac = {
        id: id,
        facilityId: this.$route.query.id,
        rentalItemDefId: rentalDefId,
        quantity: this.getQuantity
      }
      this.arrItem.push(rentalItemFac)
    },
    setValueRentalId (value) {
      const rentalItemFac = {
        id: value
      }
      this.setRentalItemFac({ rentalItemFac: rentalItemFac })
      this.actionDelete()
    },
    handleSubmit () {
      if (this.$refs.form.validate()) {
        this.createRentalItem()
      }
    },
    async updateAll (status) {
      const arrayId = []
      const B = []
      for (let i = this.arrItem.length - 1; i >= 0; i--) {
        if (!arrayId.includes(this.arrItem[i].id)) {
          arrayId.push(this.arrItem[i].id)
          B.push(this.arrItem[i])
        }
      }
      for (let i = 0; i < B.length; i++) {
        this.setRentalItemFac({ rentalItemFac: B[i] })
        await this.actionUpdate()
      }
      if (status) {
        this.setAlert(this.$t('messages.successfulUpdate'))
        setTimeout(() => {
          window.close()
        }, 1000)
      }
    },

    showDialog () {
      this.facilityPrice = 0
      this.facilityRentalPeriodName = ''
      this.openAddDialog = true
    },

    closeDialog () {
      this.openAddDialog = false
      this.$refs.form.reset()
    }
  }
}

</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table {
    thead th {
      &:nth-child(3) {
        width: 200px;
      }
    }
    tbody tr {
      td {
        .v-input {
          max-width: 80px;
        }
      }
    }
  }
}
.t-table {
  display: table;
  border-spacing: 0 20px;
  &__row {
    display: table-row;
    label,
    .v-select,
    &__cell {
      display: table-cell;
    }
    label {
      padding-right: 15px;
      text-align: right;
      white-space: nowrap;
    }
    &__cell, .v-select {
      width: 250px !important;
    }
  }
  .inputValue {
    width: 100%;
  }
}
::v-deep {
  .v-select__slot {
    .v-select__selections {
      color: #666666 !important;
      font-size: 16px !important;
    }
  }
  input{
    color: #666666 !important;
    font-size: 16px !important;
  }
  }
</style>
