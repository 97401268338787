<template>
  <div class="pa-5">
    <v-form ref="form">
      <div class="t-item mt-0">
        <label class="label-custom" style="margin-bottom: 0px !important;">{{ $t("common.facility") }}ID</label>
        <span>{{ facility.id }}</span>
      </div>

      <div class="t-item">
        <label class="label-custom" style="margin-bottom: 0px !important;">{{ $t("facility.settings.facilityType") }}</label>
        <span>{{ facility.type }}</span>
      </div>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.facilityNameManagement") }}</label>
        <v-text-field
        @input="getName($event)"
          v-model="facility.name"
          dense
          outlined
          :rules="rulesFacility.name"
          class="pa-0 medium-input"
          :disabled="!checkPerUser"
        ></v-text-field>
      </div>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.managementScreenDisplayOrder") }}</label>
        <v-select
        @input="getSelectOrder($event)"
          outlined
          dense
          class="medium-input"
          :rules="rulesFacility.order"
          v-model="facility.order"
          :items="listOrderItem"
          :disabled="!checkPerUser"
        ></v-select>
      </div>

      <v-divider></v-divider>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.forReceiptDisplay") }}</label>
        <div class="d-flex align-center">
          <v-text-field
            @input="getPostalCode1($event)"
            maxlength="3"
            :rules="rulesFacility.postalCode1"
            v-model="postalCode1"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
          <hr class="t-line" />
          <v-text-field
            @input="getPostalCode2($event)"
            :rules="rulesFacility.postalCode2"
            maxlength="4"
            v-model="postalCode2"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
        </div>
      </div>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.receiptDisplayAddress") }}</label>
        <v-text-field
          @input="getAddress1($event)"
          v-model="facilityAddress1"
          :rules="rulesFacility.address1"
          dense
          outlined
          class="pa-0 large-input"
          :disabled="!checkPerUser"
          hint="※会員ページ検索画面の施設住所に使用されます。〇〇県〇〇市等、都道府県を必ず記載下さい"
          persistent-hint
        ></v-text-field>
      </div>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.receiptDisplayTel") }}</label>
        <div class="d-flex align-center">
          <v-text-field type="number"
            @input="getTel0($event)"
            :rules="rulesFacility.tel0"
            maxlength="3"
            v-model="tel0"
            outlined
            dense
            :disabled="!checkPerUser"
            class="sort-input pa-0"
          ></v-text-field>
          <hr class="t-line" />
          <v-text-field type="number"
            @input="getTel1($event)"
            v-model="tel1"
            maxlength="4"
            :rules="rulesFacility.tel1"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
          <hr class="t-line" />
          <v-text-field type="number"
            @input="getTel2($event)"
            v-model="tel2"
            maxlength="3"
            :rules="rulesFacility.tel2"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
        </div>
      </div>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.receiptDisplayFax") }}</label>
        <div class="d-flex align-center">
          <v-text-field type="number"
            @input="getFax0($event)"
            :rules="rulesFacility.fax0"
            v-model="fax0"
            maxlength="85"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
          <hr class="t-line" />
          <v-text-field type="number"
            @input="getFax1($event)"
            :rules="rulesFacility.fax1"
            v-model="fax1"
            maxlength="85"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
          <hr class="t-line" />
          <v-text-field type="number"
            @input="getFax2($event)"
            :rules="rulesFacility.fax2"
            v-model="fax2"
            maxlength="85"
            outlined
            dense
            class="sort-input pa-0"
            :disabled="!checkPerUser"
          ></v-text-field>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="t-item mb-15">
        <label class="label-custom" style="margin-bottom: 0px !important;">{{ $t("facility.settings.askAgeOfBedSharedChildren") }}</label>
        <v-select
          @input="getBedShare($event)"
          hide-details
          outlined
          dense
          class="medium-input"
          v-model="bedShareRule"
          :items="listOrderScreen"
          :disabled="!checkPerUser"
        ></v-select>
      </div>

      <v-divider></v-divider>

      <div class="t-item">
        <label class="label-custom">{{ $t("facility.settings.facilityFloorPlanPDF") }}</label>
        <v-file-input
          accept="application/pdf"
          :rules="uploadPDFRules"
          @change="getFilePdf($event)"
          class="medium-input pdf-input message-no-hide"
          prepend-icon=""
          outlined
          dense
          :placeholder="namePdf"
          :disabled="!checkPerUser"
          :messages="[$messages.fileLimit({type: 'PDF'})]"
        ></v-file-input>
      </div>

      <v-divider></v-divider>

      <div class="t-item" style="align-items: baseline;">
        <label class="label-custom">{{ $t("facility.settings.facilityIDForRakutsu") }}</label>
        <v-text-field
          v-model="rakutsuHotelCode"
          hide-details
          outlined
          dense
          class="medium-input pa-0"
          :disabled="!checkPerUser"
        ></v-text-field>
      </div>

      <v-divider></v-divider>
      <div class="t-item">
        <label class="align-self-start">{{ $t("facility.settings.delFacility") }}</label>
        <div>
          <span>{{ $t("facility.settings.deleteNotice") }}</span>
          <br>
          <v-btn :disabled="!checkPerUser" outlined class="btn--pink-2-ounlined mt-1" @click="deleteFacility()">{{ $t('buttons.delete') }}</v-btn>
        </div>
      </div>

      <div class="text-right mt-5">
        <v-btn :disabled="!checkPerUser" class="btn--dark-2-blue mr-3" style="font-size: 16px !important" @click="updateFacility(false)">{{ $t('buttons.saveAndUpdate') }}</v-btn>
        <v-btn :disabled="!checkPerUser" class="btn--dark-2-blue" style="font-size: 17px !important"  @click="updateFacility(true)">{{ $t('buttons.saveAndClose') }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { FACILITY_ENUM, FACILITY_TYPE_ENUM } from '@/constants/enum'
import { FACILITY, GENERATE_UPLOAD_URL } from '@/api/graphql/facility-setting-detail'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { checkNumber } from '@/utils/validators'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import axios from 'axios'

export default {
  name: 'Settings',
  props: {
    facilityId: String
  },
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      maxInt: 256,
      listOrderItem: [],
      listOrderScreen: Object.keys(FACILITY_ENUM),
      facility: {
        id: null,
        type: null
      },
      bedShareRule: '',
      postalCode: '',
      type: null,
      uploadPDFRules: [
        value => !value || value.size < 104857600 || '> 10 MB !!!'
      ],
      rulesFacility: {
        name: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 256 || this.$t('rules.isInvalid')
        ],
        order: [v => !!v || this.$t('rules.isRequired')],
        address1: [v => v?.length < 256 || this.$t('rules.isInvalid')],
        tel0: [v => (v?.length < 5) || this.$t('rules.isInvalid')],
        tel1: [v => (v?.length < 6) || this.$t('rules.isInvalid')],
        tel2: [v => (v?.length < 5) || this.$t('rules.isInvalid')],
        fax0: [v => (v?.length < 86) || this.$t('rules.isInvalid')],
        fax1: [v => (v?.length < 86) || this.$t('rules.isInvalid')],
        fax2: [v => (v?.length < 86) || this.$t('rules.isInvalid')],
        postalCode1: [v => !v || /\d{3}/.test(v) || this.$t('rules.isInvalid')],
        postalCode2: [v => !v || /\d{4}/.test(v) || this.$t('rules.isInvalid')]
      },
      name: '',
      order: '',
      postalCode1: '',
      postalCode2: '',
      facilityAddress1: '',
      tel: '',
      fax: '',
      tel0: '',
      tel1: '',
      tel2: '',
      fax0: '',
      fax1: '',
      fax2: '',
      bedShare: 'Age',
      namePdf: '',
      floorPlanPdf: '',
      selectFilePdf: '',
      rakutsuHotelCode: '',
      // room type
      roomTypeName: '',
      roomTypeOrder: '',
      roomTypeBackgroundColor: '',
      roomTypeMaxOccupancy: '',
      roomTypeBedShareMaxOccupancy: '',
      roomTypeRakutsuId: '',
      statusUpdate: null
    }
  },
  created () {
    this.getFacility()
    // this.getRoomType()
    this.setListOrder()
  },

  watch: {
    async getStatusCloseTab () {
      if (this.statusUpdate) {
        await this.setAlertSuccess(this.$t('messages.successfulUpdate'))
        setTimeout(() => {
          window.close()
        }, 1000)
      }
    }
  },

  computed: {
    ...mapGetters(['getStatusCloseTab'])
  },

  methods: {
    ...mapMutations({
      setAlertSuccess: 'setAlertSuccess',
      setFacilitySettings: 'setFacilitySettings',
      setFacilityRoomType: 'setFacilityRoomType'
    }),
    ...mapActions({
      actionUpdateRoomType: 'updateRoomType',
      actionUpdate: 'updateFacility',
      actionDelete: 'deleteFacility'
    }),
    checkNumber,
    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY}`,
        variables: {
          id: parseInt(this.facilityId)
        }
      }).then((data) => {
        this.facility = data.data.facility
        this.setupData()
        this.setValueData()
      }).catch((error) => {
        console.error(error)
      })
    },
    async getRoomType () {
      await this.$apollo.query({
        query: gql`
          query ($id: Int!){
            roomType(id: $id) {
              name
              id
              order
              maxOccupancy
              bedShareMaxOccupancy
              backgroundColor
              rakutsuId
              facilityId
            }
          }
        `,
        variables: {
          id: parseInt(this.$route.query.id)
        }
      }).then((data) => {
        const roomType = data.data.roomType

        this.roomTypeName = roomType.name
        this.roomTypeOrder = roomType.order
        this.roomTypeBackgroundColor = roomType.backgroundColor
        this.roomTypeMaxOccupancy = roomType.maxOccupancy
        this.roomTypeBedShareMaxOccupancy = roomType.bedShareMaxOccupancy
        this.roomTypeRakutsuId = roomType.rakutsuId
      })
    },
    setListOrder () {
      for (let i = 1; i < 101; i++) {
        this.listOrderItem.push(i)
      }
    },
    setValueData () {
      this.rakutsuHotelCode = this.facility.rakutsuHotelCode
      this.name = this.facility.name
      this.order = this.facility.order
      this.tel = this.facility.tel
      this.fax = this.facility.fax
      this.bedShare = this.facility.bedShareRule
      this.namePdf = this.facility.floorPlanPdf
      this.postalCode = this.facility.postalCode
      if (this.facility.address1 == null) {
        this.facilityAddress1 = ''
      } else if (this.facilityAddress1 === '') {
        this.facilityAddress1 = this.facility.address1
      }
    },
    getName (event) {
      this.name = event
    },
    getSelectOrder (event) {
      this.order = event
    },
    getPostalCode1 (event) {
      this.postalCode1 = event
    },
    getPostalCode2 (event) {
      this.postalCode2 = event
    },
    getAddress1 (event) {
      this.facilityAddress1 = event
    },
    getTel0 (event) {
      this.tel0 = event
      this.tel = this.tel0 + '-' + this.tel1 + '-' + this.tel2
    },
    getTel1 (event) {
      this.tel1 = event
    },
    getTel2 (event) {
      this.tel2 = event
    },
    getFax0 (event) {
      this.fax0 = event
    },
    getFax1 (event) {
      this.fax1 = event
    },
    getFax2 (event) {
      this.fax2 = event
    },
    getBedShare (event) {
      if (event === 'いいえ') {
        this.bedShare = 'School'
      } else if (event === 'はい') {
        this.bedShare = 'Age'
      }
    },
    async getFilePdf (event) {
      try {
        if (event !== null && typeof event !== 'undefined') {
          await this.$apollo.mutate({
            mutation: GENERATE_UPLOAD_URL,
            variables: {
              contentType: event.type,
              contentLength: event.size
            }
          }).then((data) => {
            this.uploadUrl = data.data.generateUploadUrl.uploadUrl
            this.namePdf = data.data.generateUploadUrl.fileUrl

            this.changeImg = true
            this.fileImg = event
            axios.put(
              this.uploadUrl,
              event,
              {
                headers: {
                  'Content-Type': this.fileImg.type
                }
              })
          }).catch((error) => {
            console.error(error)
          })
        }
      } catch (e) {
        console.log(e)
        this.namePdf = this.facility.floorPlanPdf
      }
    },
    setupData () {
      this.type = Object.keys(FACILITY_TYPE_ENUM).find(key => FACILITY_TYPE_ENUM[key] === this.facility.type)
      if (this.tel === '') {
        if (this.facility.tel !== null) {
          const splitTel = this.facility.tel.split('-')
          this.tel0 = splitTel[0]
          this.tel1 = splitTel[1]
          this.tel2 = splitTel[2]
        }
      }
      if (this.fax === '') {
        if (this.facility.fax !== null) {
          const splitFax = this.facility.fax.split('-')
          this.fax0 = splitFax[0]
          this.fax1 = splitFax[1]
          this.fax2 = splitFax[2]
        }
      }
      if (this.postalCode1 === '') {
        if (this.facility.postalCode !== null) {
          this.postalCode1 = this.facility.postalCode.slice(0, 3)
        }
      }
      if (this.postalCode2 === '') {
        if (this.facility.postalCode !== null) {
          this.postalCode2 = this.facility.postalCode.slice(3, this.facility.postalCode.length)
        }
      }
      if (this.facility.bedShareRule !== null) {
        this.bedShareRule = this.facility.bedShareRule === 'Age' ? 'はい' : (this.facility.bedShareRule === 'School' ? 'いいえ' : '')
      }
      this.namePdf = this.facility.floorPlanPdf
      this.$refs.form.resetValidation()
    },
    async updateFacility (status) {
      this.statusUpdate = status
      if (this.$refs.form.validate()) {
        const facilitySettings = {
          id: this.$route.query.id,
          name: this.name,
          order: this.order,
          postalCode: this.postalCode1 + this.postalCode2,
          address1: this.facilityAddress1,
          tel: this.tel0 + '-' + this.tel1 + '-' + this.tel2,
          fax: this.fax0 + '-' + this.fax1 + '-' + this.fax2,
          bedShareRule: this.bedShare,
          floorPlanPdf: this.namePdf,
          rakutsuHotelCode: this.rakutsuHotelCode
        }
        // roomtype
        const facilityRoomType = {
          name: this.roomTypeName,
          order: this.roomTypeOrder,
          backgroundColor: this.roomTypeBackgroundColor,
          maxOccupancy: this.roomTypeMaxOccupancy,
          bedShareMaxOccupancy: this.roomTypeMaxOccupancy,
          rakutsuId: this.roomTypeRakutsuId,
          facilityId: this.facilityId
        }

        this.setFacilitySettings({ facilitySettings: facilitySettings })
        this.setFacilityRoomType({ facilityRoomType: facilityRoomType })

        await this.actionUpdate()
        // await this.actionUpdateRoomType()
        // this.getFacility()
        // this.getRoomType()
      }
    },

    deleteFacility () {
      const facilitySettings = {
        id: parseInt(this.$route.query.id)
      }
      this.setFacilitySettings({ facilitySettings: facilitySettings })
      this.actionDelete()
    }
  }
}
</script>

<style lang="scss" scoped>
.t-item {
  font-size: 16px !important;
  .large-input {
    width: 660px;
  }
  .sort-input {
    width: 115px;
    max-width: 115px;
  }
  .t-line {
    width: 16px;
    margin: 0 15px;
  }
  span{
    color: #666666 !important;
  }
  .align-self-start{
    color: #666666 !important;
  }
}
::v-deep {
  .label-custom{
    font-size: 16px !important;
    color: #666666 !important;
    margin-bottom: 30px;
  }
  .v-divider {
    margin: 25px 0;
  }
  .v-file-input {
    .v-input__append-inner {
      display: none
    }
  }

  .pdf-input {
    white-space: nowrap;
    width: 50px;
    text-overflow: ellipsis;

  }
  .v-text-field__slot{
    input{
      color: #666666 !important;
      font-size: 16px !important;
    }
  }
  .v-select__slot{
    .v-select__selections{
      .v-select__selection--comma{
        color: #666666 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-icon__svg{
    color: #424242 !important;
  }
  .btn--pink-2-ounlined{
    font-size: 17px !important;
    border: 3px solid #CA406A !important;
  }
}
</style>
