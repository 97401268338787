<template>
  <div class="px-main --dark">
    <v-col cols="12" lg="9">
      <h1 class="page-title-main py-4">{{ this.$route.query.name }}</h1>

      <template>
        <v-tabs v-model="tab" class="mb-10 d-inline-flex t-tab-custom">
          <v-tab
            v-for="item in items"
            :key="item.title"
            :href="'#' + item.tabUrl"
            class="px-8"
            >{{ item.title }}</v-tab
          >
        </v-tabs>

        <v-tabs-items
          :value="tab"
          touchless
          :class="isMinScreen ? 'min-width-screen bg--secondary' : isMaxScreen ? 'max-width' : ''"
        >
          <v-tab-item class="mb-10 bg--white" value="settings">
            <Settings :facilityId="facilityId" />
          </v-tab-item>

          <v-tab-item class="mb-2 bg--white" value="rental-list">
            <RentalList />
          </v-tab-item>

          <v-tab-item class="mb-2 bg--white" value="parking-place">
            <ParkingPlace />
          </v-tab-item>

          <v-tab-item class="mb-10 bg--white" value="booking-remarks">
            <BookingRemarks />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-col>
  </div>
</template>

<script>
import Settings from './settings.vue'
import RentalList from './rentalList.vue'
import ParkingPlace from './parkingPlace'
import BookingRemarks from './bookingRemarks'
import { FACILITY } from '@/api/graphql/facility-setting-detail'
import gql from 'graphql-tag'

export default {
  name: 'FacilityDetailTab',
  data () {
    return {
      isMinScreen: false,
      isMaxScreen: false,
      facility: {
        id: null,
        type: null
      },
      routeTab: null
    }
  },
  created () {
    document.title = this.$route.query.name
  },
  mounted () {
    this.getFacility()
    this.routeTab = this.$route.query.tab
    this.setWidthScreen()
  },
  watch: {
    routeTab () {
      this.routeTab = this.$route.query.tab
      this.setWidthScreen()
    },
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = this.$route.query.name || 'PMS'
      }
    }
  },
  computed: {
    facilityId () { return this.$route.query.id },
    items () {
      if (this.facility.type === 'PT') {
        return [
          {
            title: this.$t('routeTitles.facility.facilitySettings'),
            tabUrl: 'settings'
          }
        ]
      } else if (this.facility.type === 'CNV') {
        return [
          {
            title: this.$t('routeTitles.facility.facilitySettings'),
            tabUrl: 'settings'
          },
          {
            title: this.$t('contractDetail.parkingPlace'),
            tabUrl: 'parking-place'
          },
          {
            title: this.$t('routeTitles.facility.bookingRemarkByContract'),
            tabUrl: 'booking-remarks'
          }
        ]
      } else {
        return [
          {
            title: this.$t('routeTitles.facility.facilitySettings'),
            tabUrl: 'settings'
          },
          {
            title: this.$t('routeTitles.facility.rentalList'),
            tabUrl: 'rental-list'
          },
          {
            title: this.$t('contractDetail.parkingPlace'),
            tabUrl: 'parking-place'
          },
          {
            title: this.$t('routeTitles.facility.bookingRemarkByContract'),
            tabUrl: 'booking-remarks'
          }
        ]
      }
    },
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
        this.setWidthScreen()
      },
      get () {
        return this.$route.query.tab
      }
    }
  },
  methods: {
    setWidthScreen () {
      console.log('this.tab', this.$route.query.tab)
      if (this.$route.query.tab === 'operation-change-history') {
        this.isMinScreen = true
        this.isMaxScreen = false
      } else if (this.$route.query.tab === 'booking-remarks') {
        this.isMinScreen = false
        this.isMaxScreen = true
      } else {
        this.isMinScreen = false
        this.isMaxScreen = false
      }
      console.log('isMinScreen', this.isMinScreen)
      console.log('isMaxScreen', this.isMaxScreen)
    },
    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY}`,
        variables: {
          id: parseInt(this.facilityId)
        }
      }).then((data) => {
        this.facility = data.data.facility
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  components: {
    Settings,
    RentalList,
    ParkingPlace,
    BookingRemarks
  }
}
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 605px;
}
</style>
